import { useState, useEffect } from "react";
import { removeVideoFromReported, deleteVideo } from "../api/requests";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../helpers/cookies";
import { isLogin } from "../helpers/auth";
import { FaSignOutAlt, FaArrowLeft } from "react-icons/fa";
import api from "../api/axios";
import { Pagination } from "@mui/material";
import ReportedMessageComponent from "../components/ReportedMessageComponent";

export default function Home() {
  const [videos, setVideos] = useState([]);
  const [videosCount, setVideosCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [pageChange, setPageChange] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [ranks, setRanks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin()) {
      return navigate("/login");
    }

    const fetchVideo = async () => {
      let videoData;
      if (firstLoad) {
        try {
          if (searchQuery) videoData = await api.get(`/reportedVideos?searchQuery=${searchQuery}&page=${pageChange}`);
          else videoData = await api.get(`/reportedVideos?page=${pageChange}`);
          const rankingData = await api.get(`/getRanks`);
          setRanks(rankingData.data.ranks);
        } catch (err) {
          //   err.message;
          console.log(err);
        }
        if (videoData) {
          setVideos(videoData.data.reportedVideos.reportedVideosArray);
          const totalPages = Math.ceil(videoData.data.reportedVideos.count / 10);
          setPages(totalPages);
          setVideosCount(videoData.data.reportedVideos.count);
        }
        setFirstLoad(false);
      } else {
        setTimeout(async () => {
          try {
            if (searchQuery) videoData = await api.get(`/reportedVideos?searchQuery=${searchQuery}&page=${pageChange}`);
            else videoData = await api.get(`/reportedVideos?page=${pageChange}`);
            const rankingData = await api.get(`/getRanks`);
            setRanks(rankingData.data.ranks);
          } catch (err) {
            //   err.message;
            console.log(err);
          }
          if (videoData) {
            setVideos(videoData.data.reportedVideos.reportedVideosArray);
            const totalPages = Math.ceil(videoData.data.reportedVideos.count / 10);
            setPages(totalPages);
            setVideosCount(videoData.data.reportedVideos.count);
          }
        }, 5000);
      }
    };
    fetchVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, pageChange, videos]);

  return (
    <div>
      <div className="headerDiv">
        <button
          className="logoutBtn"
          onClick={() => {
            removeCookie("token");
            return navigate("/login");
          }}
        >
          <FaSignOutAlt style={{ marginBottom: "-3px", marginRight: "7px" }} />
          Logout
        </button>
        <div className="searchInputDiv">
          <input
            className="searchInputStyle"
            placeholder="Search Videos.."
            onChange={(search) => {
              setSearchQuery(search.target.value);
            }}
          />
        </div>
        <button
          className="createBtn"
          onClick={() => {
            return navigate("/");
          }}
        >
          <FaArrowLeft style={{ marginBottom: "-3px", marginRight: "6px" }} />
          Back
        </button>
      </div>
      <br />

      <h1 style={{ marginLeft: "20px" }}>Reported Videos:</h1>
      <h4 style={{ marginLeft: "20px" }}>Video count: {videosCount}</h4>
      {!videos.length && (
        <p style={{ marginLeft: "20px" }}>
          No reported videos found {searchQuery && "with search query:"} {searchQuery}
        </p>
      )}
      {videos.map((video) => {
        return (
          <div className="videoList" key={video._id}>
            {video.link && video.rank && video.game && video.reportedType && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <iframe
                    width="400"
                    height="200"
                    src={`https://www.youtube.com/embed/${video.youtubeId}`}
                    title={video._id}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  {!video.link && <p className="videoListTitle">{video.name}</p>}
                  {video.link && (
                    <a href={video.link} className="videoListTitle" target="_blank" rel="noreferrer">
                      {video.name}
                    </a>
                  )}
                  {video.link && (
                    <i
                      style={{
                        color: "lightgray",
                        marginLeft: 40,
                        fontSize: 14,
                      }}
                    >
                      Click on title to redirect to video page
                    </i>
                  )}
                </div>
                <div>
                  {video.game && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p style={{ fontSize: 14 }}>{video.game}</p>
                      <img
                        src={ranks[`${video.game}`][`${video.rank}`]}
                        width="60px"
                        height="60px"
                        alt="rank"
                        style={{ marginLeft: "20px" }}
                      ></img>{" "}
                    </div>
                  )}
                </div>
                <ReportedMessageComponent reportedType={video.reportedType} />
                <div>
                  <label for="Game">Switch Game:</label>
                  <select
                    name="Game"
                    id="Game"
                    value={video.game}
                    onChange={(val) => {
                      api.post(`/switchGame`, {
                        videoId: video.id,
                        game: val.target.value,
                      });
                      const newArr = videos.map((w) => {
                        if (w._id === video._id) {
                          w.game = val.target.value;
                        }
                        return w;
                      });
                      setVideos(newArr);
                    }}
                  >
                    <option selected disabled hidden>
                      Select
                    </option>
                    <option value="valorant">Valorant</option>
                    <option value="marvelrivals">Marvel Rivals</option>
                    <option value="cs2">Counter Strike 2</option>
                    <option value="leagueoflegends">League Of Legends</option>
                    <option value="brawlhalla">Brawlhalla</option>
                    <option value="brawlstars">Brawlstars</option>
                    <option value="rocketleague">Rocket League</option>
                    <option value="overwatch">Overwatch</option>
                    <option value="apexlegends">Apex Legends</option>
                    <option value="r6">Rainbow Six Siege</option>
                    <option value="fortnite">Fortnite</option>
                    <option value="csgo">CSGO</option>
                    <option value="dota">Dota</option>
                  </select>
                  <button
                    className="editBtn"
                    onClick={async () => {
                      let keepResponse = await removeVideoFromReported(video._id, video.game);
                      if (keepResponse.data) {
                        if (keepResponse.data.status === 200) {
                          const newArr = videos.filter((w) => w._id !== video._id);
                          const newCount = videosCount - 1;
                          setVideosCount(newCount);
                          setVideos(newArr);
                        }
                      }
                    }}
                  >
                    Keep
                  </button>
                  <button
                    className="deleteBtn"
                    onClick={async () => {
                      let deletedResponse = await deleteVideo(video._id);
                      if (deletedResponse.data) {
                        if (deletedResponse.data.status === 200) {
                          const newArr = videos.filter((w) => w._id !== video._id);
                          const newCount = videosCount - 1;
                          setVideosCount(newCount);
                          setVideos(newArr);
                        }
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
              </>
            )}
          </div>
        );
      })}
      <br />
      <br />
      <Pagination
        count={pages}
        onChange={(event, value) => {
          setPageChange(value);
        }}
      />
      <br />
      <br />
    </div>
  );
}
